import React, { useState } from "react";
import axios from "axios";

const Home = () => {
  const domainName = window.location.hostname;
  const [email, setEmail] = useState("");
  const [subject] = useState(`Message from ${domainName}`);
  const [body, setBody] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const tokenResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/actions/generate-token`
      );
      const token = tokenResponse.data.token;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/send-email`,
        {
          email,
          subject,
          body,
          token,
        }
      );
      console.log(response.data); // Handle success
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  return (
    <>
      <div className="Auth-form-container">
        <form onSubmit={handleSubmit} className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">{domainName}</h3>
            <div className="form-group mt-3">
              <div className="p-3 mb-3 bg-secondary text-white">
                This domain is available for sale. Please provide your email and
                the bid and we will get back to you shortly.
              </div>
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group mt-3">
              <label>Message</label>
              <textarea
                value={body}
                className="form-control mt-1"
                onChange={(e) => setBody(e.target.value)}
                placeholder="Message (please add a bid amount or the contact will be ignored)"
                required
              />
            </div>
            <div className="d-grid gap-2 mt-3 pt-2 mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Home;
