import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Pages/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Add more routes as needed 
        <Route path="/other" element={<OtherComponent />} />
        */}

        {/* Redirect all unmatched routes to the default page */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
