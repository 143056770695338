import React from "react";

interface DomainImages {
  [key: string]: string;
}

const Header = () => {
  const domainImages: DomainImages = {
    localhost: "/assets/images/localhost.png",
    chetak: "/assets/images/chetak.svg",
    albums: "/assets/images/albums.svg",
    cobramart: "/assets/images/cobramart.svg",
    crossmediashop: "/assets/images/crossmediashop.svg",
    owlloans: "/assets/images/owl.svg",
    synergyowl: "/assets/images/owl.svg",
    usecalibre: "/assets/images/albums.svg",
    ypictures: "/assets/images/albums.svg",
    owlkings: "/assets/images/owlkings.svg",
    // Add other domain-to-image mappings as needed
  };

  const getBaseDomain = (hostname: string) => {
    const parts = hostname.split(".");
    if (parts.length === 1) {
      return hostname;
    }
    const secondLevelDomains = [
      "co.uk",
      "com.au",
      "co.nz",
      "com.br",
      "co.za",
      "com.sg",
    ];
    const lastTwoParts = parts.slice(-2).join(".");
    if (parts.length > 2 && secondLevelDomains.includes(lastTwoParts)) {
      return parts.slice(-3, -2)[0];
    }
    return parts.slice(-2, -1)[0];
  };

  const domainName = getBaseDomain(window.location.hostname);
  const imageUrl = domainImages[domainName];

  return (
    <header className="p-3 bg-dark text-white">
      <div className="container d-flex align-items-center">
        {/* Ensure the logo and the text are in a flex container */}
        {imageUrl && (
          <img
            src={imageUrl}
            alt={`${domainName} Logo`}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.style.display = "none";
            }}
            style={{ height: "30px", marginRight: "10px" }} // Add some spacing between the logo and the domain name
          />
        )}
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <span className="fs-4 text-white">{domainName}</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
